import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { SENTRY_DSN } from '$lib/constants';
import { dev } from '$app/environment';

Sentry.init({
	dsn: SENTRY_DSN,
	enabled: !dev,
	tracesSampleRate: 0,
	integrations: []
});

export const handleError = handleErrorWithSentry();
