import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(private)/admin": [~12,[4]],
		"/(app)/ke-stazeni": [7,[2]],
		"/(app)/kontakt": [8,[2]],
		"/(private)/login": [~13,[4]],
		"/(app)/o-nas": [9,[2]],
		"/(app)/prihlasit-dite-tabor": [~10,[2]],
		"/(app)/pro-rodice": [11,[2]],
		"/(app)/[...slug]": [6,[2],[,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';